.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 90%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
}

.modal_buttons {
    display: flex;
    justify-content: flex-end;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}