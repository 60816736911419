.ff-list_container {
    margin: 1em;
    border-radius: 0.5em;
    background-color: rgba(99, 255, 208, 0.16);
    display: flex;
    flex-direction: column;
}

.ff-list_header {
    padding: 0.5em;
    border-radius: 0.5em;
    background-color: rgba(99, 255, 208, 0.50);
    height: 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ff-list_adder {
    margin: 1em;
    display: flex;
}