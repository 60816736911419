.ff-list_item {
    display: flex;
    padding: 0.25em 1em;
    border-top: 1px dotted black;
}

.ff-list_item_info {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
}

.ff-list_item:hover {
    background-color: rgba(99, 255, 208, 0.50);
}

.ff-list_item_delete_button {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
}